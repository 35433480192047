<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">

                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-orange fw-bold mb-1 mt-2" style="letter-spacing: 1px">Delete Delivery</h3>
                            
                            <p style="font-size: 1.1rem">Confirm that you want to delete your current purchases</p>                                
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <status v-model:status_data=status_data></status>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row" id="login">
                            <div class="col">
                                <button class="btn bg-transparent text-secondary btn-sm w-100 text-uppercase fw-bold pt-2 pb-2" @click="cancelDelivery()">
                                    <span style="letter-spacing: 2px"> Cancel Delivery </span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'times']" />
                                </button>
                            </div>
                        </div>

                        <div class="row mt-4" id="login">
                            <div class="col">
                                <button class="btn bg-orange text-white btn-sm w-100 text-uppercase fw-bold pt-2 pb-2" @click="this.$router.push({name: 'DeliveryDetails'})">
                                    <span style="letter-spacing: 2px"> Continue </span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'angle-right']" />
                                </button>
                            </div>
                        </div> 
                    </div>                   
                </div>  
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '../assets/scss/base/_base.scss';
    @import '../assets/scss/base/_fonts.scss';
    @import '../assets/scss/helpers/breakpoints.scss';

    .card {
        box-shadow: 0 4px 8px rgb(0 0 0 / 3%)
    }

    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    }  
</style>

<script>
    import Status from '../components/Status';

    export default {
        name: 'CancelDelivery',
        components: {
            Status,
        },
        data() {
            return {
                status_data: {
                    "success": false,
                    "success_message": null, 
                    "error": false,
                    "error_message": null
                },
                errors: [],
                loading: false
            }
        },
        methods: {
            seePassword() {
                if(document.querySelector('#password').type == 'password') {
                    document.querySelector('#password').type = 'text'
                    this.eye = 'eye-slash'
                } else {
                    document.querySelector('#password').type = 'password'
                    this.eye = 'eye'
                }
            },
            deleteAccount() {
                this.status_data.loading = true

                this.axios.post('api/users/deactivate', this.data).then(() => {
                  this.status_data.loading = false
                  this.status_data.success = true
                  this.status_data.error = false
                  this.status_data.success_message = 'Account Deleted!'

                  localStorage.removeItem('user')

                  setTimeout(() => {
                    this.$router.push({name: 'Login'});
                  }, 1500);
                  
                }).catch(errors => {
                  this.status_data.loading = false
                  this.status_data.success = false
                  this.status_data.error = true
                  this.status_data.error_message = errors.message

                  console.log(errors)
                })
            }            
        }
    }
</script>